<template>
    <footer class="footer">
      <p>&copy; 2024 POSPro. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterBar',
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #f4f6f9;
    padding: 10px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem;
    color: #4a4a4a;
  }
  </style>
  