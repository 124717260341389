// src/axios.js
import axios from 'axios';

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000/',
    baseURL: 'https://pospro.pythonanywhere.com/',
    headers: {
        'Content-Type': 'application/json',
        // Add other default headers here if needed
    },
});

export default apiClient;
