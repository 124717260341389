<template>
  <div id="app">
    <UserCount />
  </div>
</template>

<script>
import UserCount from './components/UserCount.vue';

export default {
  components: {
    UserCount,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
</style>
