<template>
    <nav class="navbar">
      <ul class="nav-items">
        <li><a href="#">Home</a></li>
        <li><a href="#">About</a></li>
        <li><a href="#">Users</a></li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBar',
  };
  </script>
  
  <style scoped>
  .navbar {
    background-color: #007ad9;
    padding: 15px;
    text-align: center;
  }
  
  .nav-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .nav-items li {
    display: inline;
  }
  
  .nav-items a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .nav-items a:hover {
    color: #f0f9ff;
  }
  </style>
  