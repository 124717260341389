<template>
    <div>
      <!-- Navbar -->
      <NavBar />
  
      <!-- Main Content -->

      <div class="center-page">
        <p-card class="user-card" header="User Information" subheader="Active Users">
          <template #content>
            <div class="p-d-flex p-ai-center p-jc-center user-info">
              <i class="pi pi-users" style="font-size: 2rem; color: #007ad9;"></i>
              <h2>{{ userData.admin_users }}</h2>
              <p>Total Admin Users</p>
            </div>
          </template>
        </p-card>
  
        <p-card class="user-card" header="Subscribed Users" subheader="Active Users">
          <template #content>
            <div class="p-d-flex p-ai-center p-jc-center user-info">
              <i class="pi pi-users" style="font-size: 2rem; color: #007ad9;"></i>
              <h2>{{ userData.subbed_users }}</h2>
              <p>Users With Sub</p>
            </div>
          </template>
        </p-card>

        <p-card class="user-card" header="Subscribed Users" subheader="Active Users">
          <template #content>
            <div class="p-d-flex p-ai-center p-jc-center user-info">
              <i class="pi pi-users" style="font-size: 2rem; color: #007ad9;"></i>
              <h2>{{ userData.staffs }}</h2>
              <p>Staff Users</p>
            </div>
          </template>
        </p-card>
      </div>
  
      <!-- Footer -->
      <FooterBar />
    </div>
  </template>
  
  <script>
  import Card from 'primevue/card';
  import NavBar from './NavBar.vue';
  import FooterBar from './FooterBar.vue';
  import apiClient from '../axios';
  
  export default {
    components: {
      'p-card': Card,
      NavBar,
      FooterBar,
    },
    data() {
      return {
        userCount: 0,
        subbedUsers: 230,
        userData: {}
      };
    },
    mounted() {
      this.fetchUserCount();
    },
    methods: {
      async fetchUserCount() {
        let data = {"passkey": "AHSFNsgsrRSsdfo5353724104#E3rUG478*$%tue64y--39ysreet35s3oe9-35952=2fF"}
        try {
          const response = await apiClient.post('/auth/users_info/', data);
          this.userData = response.data;
          console.log(this.userData);
        } catch (error) {
          console.error('Error fetching user count:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .center-page {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 80vh; /* Adjusted height to leave room for the footer */
    background-color: #f4f6f9;
  }
  
  .user-card {
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 12px;
    background: linear-gradient(135deg, #f0f9ff, #cbebff);
    transition: transform 0.3s ease-in-out;
  }
  
  .user-card:hover {
    transform: translateY(-10px);
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  h2 {
    font-size: 3rem;
    margin: 0;
    color: #007ad9;
  }
  
  p {
    font-size: 1.5rem;
    color: #4a4a4a;
    margin-top: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .center-page {
      flex-direction: column;
      height: auto;
      padding: 20px 0;
    }
  
    .user-card {
      margin-bottom: 20px;
      width: 100%;
      max-width: 300px;
    }
  
    h2 {
      font-size: 2.5rem;
    }
  
    p {
      font-size: 1.2rem;
    }
  }
  </style>
  